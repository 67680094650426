import { MapElementFactory } from 'vue2-google-maps';

export default MapElementFactory({
  name: 'directionsRenderer',

  ctr() {
    return window.google.maps.DirectionsRenderer;
  },

  events: [],

  mappedProps: {},

  data() {
    return {
      routeBounds: null,
    };
  },

  props: {
    origin: { type: Object },
    destination: { type: Object },
    waypoints: { type: Array },
    travelMode: { type: String },
    map: { type: Object },
  },

  afterCreate(directionsRenderer) {
    const directionsService = new window.google.maps.DirectionsService();

    this.$watch(
      () => [this.origin, this.destination, this.waypoints, this.travelMode, this.map],
      () => {
        const { origin, destination, waypoints, travelMode } = this;
        if (!origin || !destination || !travelMode) return;

        const polylineOptionsActual = {
          strokeColor: '#47d061',
          strokeOpacity: 1.0,
          geodesic: true,
          strokeWeight: 5,
        };

        const waypnts = waypoints.map(el => ({
          location: {
            ...el,
          },
          stopover: true,
        }));

        directionsRenderer.setOptions({ suppressMarkers: true, polylineOptions: polylineOptionsActual });
        directionsService.route(
          {
            origin,
            destination,
            waypoints: waypnts,
            travelMode,
            optimizeWaypoints: true,
          },
          (response, status) => {
            if (status !== 'OK') return;
            directionsRenderer.setOptions({ preserveViewport: true });
            directionsRenderer.setDirections(response);

            const mapResponce = directionsRenderer.getMap();
            mapResponce.panToBounds(response.routes[0].bounds, 0);
          }
        );
      }, { immediate: true },
    );
  },
});
