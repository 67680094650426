<template>
  <GmapMap
    id="google-map"
    :center="center"
    :zoom="zoom"
    ref="map"
    map-type-id="terrain"
    style="height: 100%; width: 100%"
    :options="{
      styles: gmapStyles,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false
    }"
  >

  <!-- If we decide to use Direction Service -->
  <DirectionsRenderer
    ref="directionRendere"
    travelMode="DRIVING"
    :map="map"
    :origin="pickupLatLng"
    :waypoints="waypointsLatLng"
    :destination="destinationLatLng"/>

    <div>
      <gmap-custom-marker
        v-if="pickupLatLng !== null"
        ref="pickupMarker"
        alignment="center"
        :marker="pickupLatLng"
      >
        <map-location class="icon-pickup" />
      </gmap-custom-marker>
    </div>

    <gmap-info-window
      v-if="pickup && pickupLatLng !== null && !markersDisabled"
      :options="{ pixelOffset: { height: -18 } }"
      :opened="true"
      :position="pickupLatLng"
    >
      <booking-marker
        type="start"
        :color="colorPickupMarker"
        :address="pickup.description"
        :eta="getPickUpEta"
        :disableEta="true"
        >
      </booking-marker>
    </gmap-info-window>

    <div>
      <gmap-custom-marker
        v-if="destinationLatLng !== null"
        :marker="destinationLatLng"
        ref="destinationMarker"
        alignment="center"
        id="destination"
      >
        <map-location class="icon-destination" />
      </gmap-custom-marker>
    </div>

    <gmap-info-window
      v-if="destination && destinationLatLng !== null && !markersDisabled"
      :options="{ pixelOffset: { height: -18 } }"
      :opened="true"
      :position="destinationLatLng"
    >
      <booking-marker
        type="end"
        :color="colorDestinationMarker"
        :address="destination.description">
      </booking-marker>
    </gmap-info-window>

    <div>
      <gmap-custom-marker
        v-for="(waypoint, index) in waypointsLatLng"
        :key="index"
        alignment="center"
        :marker="waypoint"
      >
        <map-location />
      </gmap-custom-marker>
    </div>

    <gmap-info-window
      v-if="waypoints.length && !markersDisabled"
      v-for="(waypoint, index) in waypointsLatLng"
      :key="index"
      :options="{ pixelOffset: { height: -18 } }"
      :opened="true"
      :position="waypoint"
    >
      <booking-marker
        type="via"
        :color="colorWaypointMarker"
        :address="waypoints[index].description">
      </booking-marker>
    </gmap-info-window>

    <div>
      <gmap-custom-marker
        v-if="driverStatesPositions.length"
        v-for="(driver, index) in driverStatesPositions"
        :key="index"
        :marker="driver"
        alignment="center"
      >
        <driver-state-car />
      </gmap-custom-marker>
    </div>

    <!-- If we want to use stroke line -->
    <!-- <gmap-polyline :options="{ strokeColor: '#fff', strokeWeight: 9, geodesic: true }" :path="markerRoute" />
    <gmap-polyline :options="{ strokeColor: '#47d061', strokeWeight: 5, geodesic: true }" :path="markerRoute" /> -->
  </GmapMap>
</template>

<script>
import { mapGetters } from 'vuex';
import { getGoogleMapsAPI } from 'gmap-vue';
import GmapCustomMarker from 'vue2-gmap-custom-marker';

import gmapStyles from './gmapStyles';

import DirectionsRenderer from './mixins/DirectionsRenderer';
import { iconLoader } from '../../helpers/iconsLoader';
import coordinates from './mixins/coordinates';
import BookingMarker from './BookingMarker.vue';

export default {
  name: 'GoogleMap',
  components: {
    BookingMarker,
    GmapCustomMarker,
    ...iconLoader.booking,
    DirectionsRenderer,
  },
  mixins: [coordinates],
  props: {
    pickup: Object,
    waypoints: Array,
    destination: Object,
    driverLatLng: Object,
    pickupDraggable: Boolean,
    defaultCenterLatLng: Object,
    taxiIconUrl: String,
    colorPickupMarker: String,
    colorDestinationMarker: String,
    colorWaypointMarker: String,
    source: String,
    status: String,
    markersDisabled: Boolean,
    disableInfoWindow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      zoom: 12,
      show: true,
      map: null,
      gmapStyles,
    };
  },
  watch: {
    pickup: {
      handler(value) {
        if (!value) return;

        const params = {
          latitude: value.latitude,
          longitude: value.longitude,
        };

        this.$store.dispatch('bookings/getEtaPickup', params);
        if (this.source === 'expandItem') this.fitBounds();
      },
      immediate: true,
    },
  },
  methods: {
    latLngToProviderFormat(latLng) {
      return { lng: latLng.longitude, lat: latLng.latitude };
    },
    pickupUpdate(event) {
      const pickupCoordinates = {
        longitude: event.mapboxEvent.target._lngLat.lng,
        latitude: event.mapboxEvent.target._lngLat.lat,
      };
      this.$emit('pickupUpdate', pickupCoordinates);
    },
    fitBounds() {
      if (this.map) {
        const bounds = new this.google.maps.LatLngBounds(); // eslint-disable-line
        if (this.center) bounds.extend(this.center);
        if (this.pickupLatLng) bounds.extend(this.pickupLatLng);
        if (this.destinationLatLng) bounds.extend(this.destinationLatLng);
        if (this.driverPosition) bounds.extend(this.driverPosition);
        if (this.waypointsLatLng && this.waypointsLatLng.length > 0) {
          this.waypointsLatLng.forEach((waypoint) => {
            bounds.extend(waypoint);
          });
        }

        if (this.source === 'expandItem') {
          const bookingParams = {
            top: 42,
            bottom: 0,
            left: 0,
            right: 0,
          };

          this.map.fitBounds(bounds, bookingParams);
          this.map.setCenter(bounds.getCenter());
        } else {
          const bookingParams = {
            top: 42,
            bottom: 0,
            left: this.map.getDiv().offsetWidth * 0.5,
            right: this.map.getDiv().offsetHeight * 0.35,
          };

          this.map.fitBounds(bounds, bookingParams);
        }

        if (this.map.getZoom() > 15) {
          this.map.setZoom(12);
        }
      }
    },
  },
  async mounted() {
    this.map = await this.$refs.map.$mapPromise;
    if (this.source === 'expandItem') this.fitBounds();
  },
  computed: {
    ...mapGetters({
      mapStyle: 'bookingChannel/mapStyle',
      maps: 'bookingChannel/maps',
      accessToken: 'bookingChannel/mapAccessToken',
      getPickUpEta: 'bookings/getPickUpEta',
      availableDrivers: 'driverStates/availableDrivers',
    }),
    google: getGoogleMapsAPI,
    markerRoute() {
      if (this.pickup && this.destination) {
        const route = [];
        const destinationRoute = {
          address: this.destination.description,
          lng: this.destination.longitude,
          lat: this.destination.latitude,
        };
        const pickupRoute = {
          address: this.pickup.description,
          lng: this.pickup.longitude,
          lat: this.pickup.latitude,
        };

        route.push(pickupRoute);
        this.waypoints.forEach((el) => {
          const waypoint = {
            address: el.description,
            lng: el.longitude,
            lat: el.latitude,
          };

          route.push(waypoint);
        });

        route.push(destinationRoute);
        return route;
      }

      return [];
    },
  },
};
</script>

<style>
.gm-style-iw-ch {
  display: none !important;
}
</style>

<style scoped>
.via-pin {
  font-size: 22px;
}
.icon-destination {
  color: #000000;
}
.icon-pickup {
  color: #47d061
}
</style>
